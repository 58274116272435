var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.options,"filterable":true,"props":{
              label:'name',
              value:'value',
              emitPath:false,
              children:'children',
              checkStrictly:true
             },"size":"medium","clearable":""},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }